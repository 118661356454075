import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { TranslateService } from '@ngx-translate/core';
import { IonInput } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-order-type',
  templateUrl: './select-order-type.page.html',
  styleUrls: ['./select-order-type.page.scss'],
})
export class SelectOrderTypePage implements OnInit {
  @Input() just_select_address: boolean = false;
  @Input() just_select_order_type: boolean = false;
  @Input() currentUI: string = 'order-type';
  @Input() selected_branch: any = null;
  @Input() selectFavouriteRestaurant: boolean = false;
  @Input() showAll: boolean = false;

  @Input() tableSelectRestaurant: any = null;
  public tableListOfRestaurant: any[] = [];
  public selectedTable: any = null;
  public remaningTime: number = 3;
  public previousRestaurant: string = '';

  @Input() is_from_checkout_page: any = false;

  @ViewChild('googleaddress', { read: IonInput }) googleaddress: IonInput;

  public addressTemp = '';

  hasMultipleRestaurants: boolean = false;
  favouriteRedirectCountDown: number = 10;
  redirectIntervel = null;
  loadingFavourite: boolean = false;

  /**
   * when user already has a favourite restauratn and select delivery as order method 
   * so need to select where to deliver
   */
  public isFavouriteRestaurantDeliverySearch: boolean = false;

  // when franchise mode is on, there is a special way to get available delivery methods
  // check all the branches and get all available delivery methods
  public availableDeliveryMethodsFranchise: string[] = [];

  @Input() isFromMenuPage:boolean = false;

  constructor(public modalController: ModalController, public appService: AppServiceService, public translate: TranslateService, public router: Router) {
    this.hasMultipleRestaurants = this.appService.appObject.settings && this.appService.appObject.settings.app_configuration && this.appService.appObject.settings.app_configuration.has_multiple_restaurants;
  }

  async ngOnInit() {

    if (!this.selected_branch && this.appService.appObject.settings.is_franchise === 'true' && this.currentUI === 'order-type') {
      this.availableDeliveryMethodsFranchise = this.appService.getAvailableOrderMethodsFromBranches();
    }

    // if user has already selected favourite restaurant, then redirect into favourite restaurant 
    if (!this.tableSelectRestaurant && !this.showAll && !this.selected_branch && !this.just_select_order_type && this.appService.appObject.settings.is_franchise === 'true' && this.appService.appObject.settings.app_configuration?.has_multiple_restaurants && this.appService.appObject.favouriteRestaurant) {
      // console.log('has favourite');
      this.currentUI = 'redirect-to_favourite';
      this.loadingFavourite = true;
      await this.appService.getFavouriteRestaurantData(this.appService.appObject.favouriteRestaurant.slug);
      this.availableDeliveryMethodsFranchise = this.appService.findSelectedRestaurantOrderTypes(this.appService.appObject.favouriteRestaurant.openDetails, true);
      this.loadingFavourite = false;
      // this.redirectIntervel = setInterval(() => {
      //   this.favouriteRedirectCountDown--;
      //   if (this.favouriteRedirectCountDown === 0) {
      //     this.clearRedirectInterval();
      //     this.redirectTofavouriteRestaurant();
      //   }
      // }, 1000);
      return;
    }

    if (this.selectFavouriteRestaurant) {
      if (this.appService.appObject.myAddress.name == "" && this.appService.appObject.myAddress.lat == "" && this.appService.appObject.myAddress.lng == "") {
        this.appService.getCurrentLocation();
      }
      // initialize google places auto complete input once delivery selected
      // set timeout to avoid expressionchangedafter exception for loading spinner
      setTimeout(() => { this.appService.appObject.isLoading = true; }, 10);
      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
        this.appService.appObject.isLoading = false;
      }, 2000);

      this.currentUI = 'location-for-favourite';

      return;
    }

    // get available ordering type of selected branch
    if (!this.just_select_order_type && this.selected_branch && this.selected_branch.openDetails) {
      this.appService.findSelectedRestaurantOrderTypes(this.selected_branch.openDetails);
    } else if (!this.just_select_order_type && this.appService.appObject.settings?.main_restaurant?.openDetails) {
      this.appService.findSelectedRestaurantOrderTypes(this.appService.appObject.settings.main_restaurant.openDetails);
    }

    if (this.tableSelectRestaurant) {
      // If user has already pending payment order on another restaurant, redirect into that restaurant menu instead of selected restaurant
      if (this.appService.appObject.orderObject.selectedTable && this.appService.appObject.orderObject.selectedTable.restaurants_id && this.tableSelectRestaurant.id !== this.appService.appObject.orderObject.selectedTable.restaurants_id) {
        // console.log('redirect into that restaurant');
        if (this.appService.appObject.restaurants) {
          let previouslySelectedRestaurant = this.appService.appObject.restaurants.find((rest) => rest.id === this.appService.appObject.orderObject.selectedTable.restaurants_id);
          if (previouslySelectedRestaurant) {
            this.previousRestaurant = previouslySelectedRestaurant.name;
            this.appService.appObject.selectedRestaurantId = previouslySelectedRestaurant.id;
            this.startCountDown(previouslySelectedRestaurant.slug);
          }
        }
      } else {
        // this.appService.getUserUnpaidActiveOrders();
        this.tableListOfRestaurant = [];
        this.appService.fetchAllTablesOfRestaurant(this.tableSelectRestaurant.id).then((response: any) => {
          if (response.status && response.data) {
            this.tableListOfRestaurant = response.data;

            this.appService.appObject.selectedRestaurantId = this.tableSelectRestaurant.id;
            if (this.appService.appObject.orderObject && this.appService.appObject.orderObject.selectedTable && this.appService.appObject.orderObject.selectedTable.saved) {
              // console.log('this.appService.appObject.orderObject.selectedTable : ' , this.appService.appObject.orderObject.selectedTable);
              this.startCountDown(this.tableSelectRestaurant.slug);
            }
          }
        }).catch((error) => {
          console.log('error while fetching tables : ', error);
        });
      }
    }

    if (this.is_from_checkout_page) {
      this.addressTemp = this.appService.appObject.myAddress ? this.appService.appObject.myAddress.name : '';
      // initialize google places auto complete input once delivery selected
      setTimeout(() => {
        this.appService.appObject.isLoading = true;
      }, 10);
      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
        this.appService.appObject.isLoading = false;
      }, 2000);
    }
  }

  /**
   * Clear user redirect to favourite restaurant interval
   * @param dismiss dismiss after clear interval
   */
  clearRedirectInterval(dismiss: boolean = false) {
    if (this.redirectIntervel) {
      clearInterval(this.redirectIntervel);
      this.redirectIntervel = null;
    }

    if (dismiss) this.dismiss();
  }

  async dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
    });
  }

  /**
   * Trigger when user click choose address
   * @param orderType order type 'delivery', 'pickup', 'dine_in'
   */
  async selectTypeAsDelivery(orderType: string = 'delivery') {
    this.appService.appObject.errors.address = '';
    // console.log('this.appService.appObject.myAddress : ', this.appService.appObject.myAddress);
    if (this.appService.appObject.myAddress.name != "" && this.appService.appObject.myAddress.lat != "" && this.appService.appObject.myAddress.lng != "") {
      this.appService.appObject.selectedDeliveryMethod = this.appService.appObject.selectedDeliveryMethod;

      // if the user want to change the ordering type and select the address
      // then stop once user had it
      // if (this.just_select_order_type) {
      //   this.dismiss();
      //   return;
      // }

      this.appService.findNearByRestaurants((this.selected_branch ? 'branch-selected' : 'do-not-redirect'), false).then(async (response) => {
        // if the user is from checkout page, for just change the address
        // must calculate the order amount
        if (this.selected_branch || this.is_from_checkout_page || this.just_select_order_type) {
          // console.log('this.appService.appObject.selectedRestaurantDetails.id : ' , this.appService.appObject.selectedRestaurantDetails.id);
          // console.log('this.appService.appObject.restaurants : ', this.appService.appObject.restaurants);
          let selectedBranch = this.appService.appObject.restaurants.find((restaurant) => {
            return restaurant.id === (this.selected_branch ? this.selected_branch.id : this.appService.appObject.selectedRestaurantDetails.id)
          }); // this.selected_branch.id
          // console.log('selectedBranch : ' , selectedBranch)
          if (selectedBranch) {
            if (this.selected_branch) this.router.navigate(['restaurant', selectedBranch.slug]);
            // else if from checkout page or ordering main 2 page order type change popup
            // then calculate cart amount and close popup
            else if (this.is_from_checkout_page || this.just_select_order_type) {
              await this.appService.calculateCartAmounts();
            }
            this.dismiss();
          } else {
            // console.log('selected restaurant won\'t delivery to the selected address');
            if (this.is_from_checkout_page || this.just_select_order_type) this.appService.clearAddress('selected_restaurant_cannot_delivery_to_selected_address');

            this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
          }
        } else {
          if (this.isFavouriteRestaurantDeliverySearch) {
            let favouriteBranch = this.appService.appObject.restaurants.find((restaurant) => {
              return restaurant.id === this.appService.appObject.favouriteRestaurant.id
            });
            if(favouriteBranch){
              this.dismiss();
              this.router.navigate(['restaurant', favouriteBranch.slug]);
            }else{
              this.appService.appObject.errors['address'] = ['your_favourite_restaurant_cannot_delivery_to_selected_address'];
            }
          } else {
            if (this.appService.appObject.restaurants.length === 1 && this.appService.appObject.restaurants[0].slug && (!this.appService.appObject.settings.is_franchise || this.appService.appObject.settings.is_franchise === 'false' || !this.appService.appObject.settings.app_configuration?.has_multiple_restaurants)) {
              this.dismiss();
              this.router.navigate(['restaurant', this.appService.appObject.restaurants[0].slug]);
            } else if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length > 0 || (this.appService.appObject.settings.is_franchise === 'true' && this.appService.appObject.settings.app_configuration?.has_multiple_restaurants)) {
              this.dismiss();
              this.router.navigate(['restaurant-list']);

            } else {
              this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
            }
          }
        }
      });
    } else {
      // if lat lng address not availbale then mark as invalid
      this.appService.appObject.errors['address'] = ['address_is_not_valid'];
    }
  }

  /**
   * Will trigger when user select an option from order type select popup
   * @param option selected order type option
   */
  async selectOrderType(option) {
    // if came from the order main page and not delivery (we must select the address if delivery)
    // just let the user to select ordering type
    if (option !== 'delivery' && (this.just_select_order_type || this.selected_branch)) {
      this.appService.appObject.selectedDeliveryMethod = option;

      // if user came from branches slider, then redirect into branch menu after selecting order type
      if (this.selected_branch) {
        // if bar mode is enabled and user choosed dine_in, then open table selecter, otherwise redirect into menu page
        if (option === 'dine_in' && this.selected_branch.is_bar_mode && this.selected_branch.is_bar_mode === 'true') {
          this.appService.openTableSelect(this.selected_branch);
        } else {
          this.router.navigate(['restaurant', this.selected_branch.slug]);
        }
      }

      // calculate cart total when user change ordering type
      this.appService.calculateCartAmounts();

      this.dismiss();
      return;
    }

    // // if franchise mode is enabled
    // if (this.appService.appObject.settings.is_franchise === 'true') {
    //   setTimeout(() => {
    //     this.appService.initGoogle(this.googleaddress);
    //     this.appService.appObject.isLoading = false;
    //   }, 2000);

    //   if(this.appService.appObject.favouriteRestaurant){

    //   }else{
    //     this.currentUI = (option === 'delivery' ? 'delivery-address' : (option === 'pickup' ? 'pickup-address' : (option === 'dine_in') ? 'dinein-address' : ''));

    //     if (this.appService.appObject.myAddress.name == "" && this.appService.appObject.myAddress.lat == "" && this.appService.appObject.myAddress.lng == "") {
    //       this.appService.getCurrentLocation();
    //     }
    //   }
    // } else {

    // }
    if (option === 'delivery' || (this.appService.appObject.settings.is_franchise === 'true' && this.appService.appObject.settings.app_configuration?.has_multiple_restaurants)) {
      this.currentUI = (option === 'delivery' ? 'delivery-address' : (option === 'pickup' ? 'pickup-address' : (option === 'dine_in') ? 'dinein-address' : ''));
      // this.currentUI = 'delivery-address';
      if (this.appService.appObject.myAddress.name == "" && this.appService.appObject.myAddress.lat == "" && this.appService.appObject.myAddress.lng == "") {
        this.appService.getCurrentLocation();
      }

      this.appService.appObject.selectedDeliveryMethod = option;
      this.appService.updateAppObject();

      // initialize google places auto complete input once delivery selected
      this.appService.appObject.isLoading = true;
      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
        this.appService.appObject.isLoading = false;
      }, 2000);
    } else {
      this.appService.appObject.selectedDeliveryMethod = option;

      await this.appService.clearSelectedRestaurant();
      await this.appService.findNearByRestaurants('', true);

      this.appService.updateAppObject();

      // console.log('rest : ', this.appService.appObject.restaurants);

      // if (this.hasMultipleRestaurants) {
      //   this.router.navigate(['restaurant-list']);
      // } else if (!this.hasMultipleRestaurants) {
      //   if (this.appService.appObject.settings.main_restaurant.slug) this.router.navigate(['restaurant', this.appService.appObject.settings.main_restaurant.slug]);
      // }

      // if multiple restaurants is disabled then navigate directly into main restaurant menu page
      // this is a tricky solution. If has multiple option is disabled, then replace restaurant list with main restaurant
      if (this.appService.appObject.settings && this.appService.appObject.settings.app_configuration && !this.appService.appObject.settings.app_configuration.has_multiple_restaurants) {
        this.appService.appObject.restaurants = [this.appService.appObject.settings.main_restaurant];
      }

      if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length > 1) {
        this.router.navigate(['restaurant-list']);
      } else if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length === 1) {
        // if bar mode is on, then redirect to table select ui
        if (option === 'dine_in' && this.appService.appObject.restaurants[0].is_bar_mode && this.appService.appObject.restaurants[0].is_bar_mode === 'true') {
          this.appService.openTableSelect(this.appService.appObject.restaurants[0]);
        } else {
          if (this.appService.appObject.restaurants[0].slug) this.router.navigate(['restaurant', this.appService.appObject.restaurants[0].slug]);
        }
      } else if (!this.appService.appObject.restaurants || !this.appService.appObject.restaurants.length) {
        if (this.appService.appObject.settings && this.appService.appObject.settings.app_configuration && !this.appService.appObject.settings.app_configuration.has_multiple_restaurants) {
          this.router.navigate(['restaurant', this.appService.appObject.settings.main_restaurant.slug]);
        } else {
          this.translate.get('key.there_is_no_' + this.appService.appObject.selectedDeliveryMethod + '_available_restaurants_at_the_moment').toPromise().then((data) => {
            this.appService.showToast(data, 'danger', 'bottom', 5000);
          }).catch((err) => {
            console.log('error while fetching not available : ', err);
          })
        }
      }
      this.dismiss();
    }
  }

  cancelTableSelect() {
    this.dismiss();
  }

  selectATable() {
    if (this.selectedTable && this.tableSelectRestaurant && this.tableSelectRestaurant.slug) {
      this.appService.appObject.orderObject.selectedTable = this.selectedTable;

      // console.log('this.appService.appObject.orderObject.selectedTable : ' , this.appService.appObject.orderObject.selectedTable);
      this.appService.updateAppObject();
      if(!this.isFromMenuPage){
        this.router.navigate(['restaurant', this.tableSelectRestaurant.slug]);
      }
      // this.dismiss();
      this.modalController.dismiss({
        'dismissed': true,
      });
    }
  }

  /**
   * Start countdown when user has already unpaid kitchen order
   * @param slug slug to redirect after countdown
   */
  startCountDown(slug) {
    let int = setInterval(() => {
      this.remaningTime--;
      if (!this.remaningTime) {
        clearInterval(int);
        this.router.navigate(['restaurant', slug]);
        this.dismiss();
      }
    }, 1000);
  }

  /**
   * Redirect user to favourite 
   */
  redirectTofavouriteRestaurant() {
    if (this.appService.appObject.favouriteRestaurant && this.appService.appObject.favouriteRestaurant.slug) {
      this.router.navigate(['restaurant', this.appService.appObject.favouriteRestaurant.slug]);
      if (this.redirectIntervel) {
        clearInterval(this.redirectIntervel);
        this.redirectIntervel = null;
      }
      this.dismiss();
    }
  }

  async changeFavouriteRestaurant() {
    this.appService.appObject.favouriteRestaurant = null;
    this.appService.updateAppObject();


    this.modalController.dismiss();

    let componentProps = {
      'currentUI': 'order-type',
      'selectFavouriteRestaurant': true
    }

    const cartModal = await this.modalController.create({
      component: SelectOrderTypePage,
      cssClass: 'custom-popup',
      componentProps: componentProps
    });

    await cartModal.present();
  }

  async goToNearbyRestaurantList() {
    await this.appService.getRestaurantListForFavourite();
    if (!this.appService.appObject.restaurants.length) {
      this.appService.appObject.errors['address'] = ['there_are_no_restaurants_available_around_selected_address_at_the_moment'];
      return;
    }
    this.router.navigate(['restaurant-list'], { queryParams: { selectfavourite: true } });
    this.dismiss();
  }

  /**
   * When user select delivery type from favourite restaurant
   * @param option selected option
   */
  onFavoriteModeDeliveryMethodSelect(option) {
    this.appService.appObject.selectedDeliveryMethod = option;
    this.appService.updateAppObject();
    if (option !== 'delivery') {
      this.router.navigate(['restaurant', this.appService.appObject.favouriteRestaurant.slug]);
      this.dismiss();
    } else {
      // console.log('delivery selected');
      this.currentUI = 'delivery-address';
      // this.currentUI = 'delivery-address';
      if (this.appService.appObject.myAddress.name == "" && this.appService.appObject.myAddress.lat == "" && this.appService.appObject.myAddress.lng == "") {
        this.appService.getCurrentLocation();
      }

      this.appService.appObject.selectedDeliveryMethod = option;
      this.appService.updateAppObject();

      this.isFavouriteRestaurantDeliverySearch = true;

      // initialize google places auto complete input once delivery selected
      this.appService.appObject.isLoading = true;
      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
        this.appService.appObject.isLoading = false;
      }, 2000);
    }
  }
}
